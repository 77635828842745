import './App.css';
import {lazy, Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "../src/root/redux/Store";
import SplashScreen from "./root/components/Loading/SplashScreen";
import {GoogleOAuthProvider} from "@react-oauth/google";
import GOOGLE_API_KEY from "./root/settings/settings";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Root = lazy(() => import("./root/screens/index"));

function App() {

    return (
        <Provider store={store}>
            <BrowserRouter>
                <GoogleOAuthProvider clientId={GOOGLE_API_KEY}>
                    <Suspense fallback={<SplashScreen/>}>
                        <Root/>
                    </Suspense>
                    <ToastContainer/>

                </GoogleOAuthProvider>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
