import {useDispatch, useSelector} from "react-redux";

const initialState = {
    isDrawerOpen: false
}

export const DRAWER_ACTIONS = {
    TOGGLE_DRAWER: "TOGGLE_DRAWER",
    OPEN_DRAWER: "OPEN_DRAWER",
    CLOSE_DRAWER: "CLOSE_DRAWER"
}


const DrawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case DRAWER_ACTIONS.TOGGLE_DRAWER:
            return {...state, isDrawerOpen: !state.isDrawerOpen};
        case DRAWER_ACTIONS.OPEN_DRAWER:
            return {...state, isDrawerOpen: true};
        case DRAWER_ACTIONS.CLOSE_DRAWER:
            return {...state, isDrawerOpen: false};

        default:
            return state;
    }
}


export function useDrawer() {
    const dispatch = useDispatch();
    const isDrawerOpen = useSelector(state => state.DrawerReducer.isDrawerOpen);

    const toggleTheme = () => {
        dispatch({type: DRAWER_ACTIONS.TOGGLE_DRAWER});
    }
    const openDrawer = () => {
        dispatch({type: DRAWER_ACTIONS.OPEN_DRAWER});
    }
    const closeDrawer = () => {
        dispatch({type: DRAWER_ACTIONS.CLOSE_DRAWER});
    }

    const actions = {
        openDrawer: openDrawer,
        closeDrawer: closeDrawer,
        toggleDrawer: toggleTheme
    }

    return {isDrawerOpen, actions}
}


export default DrawerReducer;