import {combineReducers} from "redux";
import ThemeReducer from "./Reducers/ThemeReducer";
import DrawerReducer from "./Reducers/DrawerReducer";


const rootReducer = combineReducers({
    ThemeReducer,
    DrawerReducer
})


export default rootReducer;