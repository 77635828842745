import NProgress from "nprogress";
import "nprogress/nprogress.css";
import {useEffect} from "react";
import logo from "../../assets/img/logo.png";
import checkDarkModePrefence from "../../utils/Theme";

export default function SplashScreen({className, ...props}) {
    const isDarkMode = checkDarkModePrefence();
    const filterStyles = !isDarkMode ? {filter: 'brightness(1) invert(0)'} : {filter: 'brightness(0) invert(1)'};

    useEffect(() => {
        // checking user's preference
        if (isDarkMode) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }

        NProgress.start();

        return () => {
            NProgress.done();
        };
    });


    return (
        <div
            className={`h-[100vh] flex flex-col justify-center items-center w-full bg-white dark:bg-darkBg ${className}`}>
            <img alt={"logo"} style={filterStyles} src={logo}/>
        </div>

    )
}